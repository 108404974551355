import React from 'react';
import './BlogDefault.css';

function Blog() {
  return (
    <div className="individual-blog-container">
      <h1 className="placeholder-text">Coming soon...</h1>
    </div>
  );
}

export default Blog;
